@use "src/theme";

.controls {
  position: fixed;
  bottom: 8px;
  right: 8px;

  @media (min-width: theme.$editor-mobile-breakpoint-min) {
    flex-direction: row-reverse;
    left: 8px;
    right: unset;
  }
}

$z-index: 9999;

:root {
  --color-transparent: hsla(0, 0%, 100%, .7);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --color-progress-light: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --color-progress-dark: #bb86fc;
  --color-progress-bgo: 0.2
}

.Toastify {
  pointer-events: all;
}

.Toastify__toast-container {
  z-index: $z-index;
  -webkit-transform: translateZ(#{$z-index}px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left)
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%)
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right)
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left)
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%)
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right)
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 10px;
    left: env(safe-area-inset-left);
    margin: 0
  }

  .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0)
  }

  .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0)
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: auto
  }
}

.Toastify__toast {
  --y: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--font-body-family);
  background-color: var(--color-background);
  color: var(--color-foreground);
  font-size: 1rem;
  cursor: default;
  direction: ltr;
  z-index: 0;
  overflow: hidden
}

.Toastify__toast--stacked {
  position: absolute;
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform .3s
}

.Toastify__toast--stacked[data-collapsed] .Toastify__close-button, .Toastify__toast--stacked[data-collapsed] .Toastify__toast-body {
  transition: opacity .1s
}

.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible
}

.Toastify__toast--stacked[data-collapsed=true]:not(:last-child) > * {
  opacity: 0
}

.Toastify__toast--stacked:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--g) * 1px);
  bottom: 100%
}

.Toastify__toast--stacked[data-pos=top] {
  top: 0
}

.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0
}

.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top
}

.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom
}

.Toastify__toast--stacked:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: scaleY(3);
  z-index: -1
}

.Toastify__toast--rtl {
  direction: rtl
}

.Toastify__toast--close-on-click {
  cursor: pointer
}

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  -ms-flex: 1;
  flex: 1
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: .5s
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: .3s
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    //border-radius: 0
  }
}

.Toastify__progress-bar-theme--light {
  background: var(--color-progress-light)
}

.Toastify__progress-bar-theme--dark {
  background: var(--color-progress-dark)
}

.Toastify__progress-bar--info {
  background: var(--color-progress-info)
}

.Toastify__progress-bar--success {
  background: var(--color-progress-success)
}

.Toastify__progress-bar--warning {
  background: var(--color-progress-warning)
}

.Toastify__progress-bar--error {
  background: var(--color-progress-error)
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: var(--color-transparent)
}


.Toastify__close-button {
  color: #fff;
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  opacity: .7;
  transition: opacity .3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
  z-index: 1
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 1rem;
}

.Toastify__close-button:focus, .Toastify__close-button:hover {
  opacity: 1
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1)
  }
  to {
    transform: scaleX(0)
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index;
  opacity: .7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards
}

.Toastify__progress-bar--controlled {
  transition: transform .2s
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  transform-origin: right;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--wrp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius)
}

.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0
}

.Toastify__progress-bar--bg {
  opacity: var(--color-progress-bgo);
  width: 100%;
  height: 100%
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin .65s linear infinite
}

@keyframes Toastify__bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)
  }
  75% {
    transform: translate3d(10px, 0, 0)
  }
  90% {
    transform: translate3d(-5px, 0, 0)
  }
  to {
    transform: none
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0)
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0)
  }
}

@keyframes Toastify__bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)
  }
  75% {
    transform: translate3d(-10px, 0, 0)
  }
  90% {
    transform: translate3d(5px, 0, 0)
  }
  to {
    transform: none
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0)
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0)
  }
}

@keyframes Toastify__bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)
  }
  75% {
    transform: translate3d(0, 10px, 0)
  }
  90% {
    transform: translate3d(0, -5px, 0)
  }
  to {
    transform: translateZ(0)
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0)
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0)
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)
  }
}

@keyframes Toastify__bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)
  }
  75% {
    transform: translate3d(0, -10px, 0)
  }
  90% {
    transform: translate3d(0, 5px, 0)
  }
  to {
    transform: none
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0)
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0)
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)
  }
}

.Toastify__bounce-enter--bottom-left, .Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--bottom-right, .Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--bottom-left, .Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--bottom-right, .Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3)
  }
  50% {
    opacity: 1
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3)
  }
  to {
    opacity: 0
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut
}

@keyframes Toastify__flipIn {
  0% {
    transform: perspective(400px) rotateX(90deg);
    animation-timing-function: ease-in;
    opacity: 0
  }
  40% {
    transform: perspective(400px) rotateX(-20deg);
    animation-timing-function: ease-in
  }
  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }
  80% {
    transform: perspective(400px) rotateX(-5deg)
  }
  to {
    transform: perspective(400px)
  }
}

@keyframes Toastify__flipOut {
  0% {
    transform: translate3d(0, var(--y), 0) perspective(400px)
  }
  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotateX(-20deg);
    opacity: 1
  }
  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut
}

@keyframes Toastify__slideInRight {
  0% {
    transform: translate3d(110%, 0, 0);
    visibility: visible
  }
  to {
    transform: translate3d(0, var(--y), 0)
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    transform: translate3d(-110%, 0, 0);
    visibility: visible
  }
  to {
    transform: translate3d(0, var(--y), 0)
  }
}

@keyframes Toastify__slideInUp {
  0% {
    transform: translate3d(0, 110%, 0);
    visibility: visible
  }
  to {
    transform: translate3d(0, var(--y), 0)
  }
}

@keyframes Toastify__slideInDown {
  0% {
    transform: translate3d(0, -110%, 0);
    visibility: visible
  }
  to {
    transform: translate3d(0, var(--y), 0)
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    transform: translate3d(0, var(--y), 0)
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0)
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    transform: translate3d(0, var(--y), 0)
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0)
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    transform: translate3d(0, var(--y), 0)
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0)
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    transform: translate3d(0, var(--y), 0)
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0)
  }
}

.Toastify__slide-enter--bottom-left, .Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--bottom-right, .Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--bottom-left, .Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: .3s
}

.Toastify__slide-exit--bottom-right, .Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in;
  animation-duration: .3s
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in;
  animation-duration: .3s
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in;
  animation-duration: .3s
}

@keyframes Toastify__spin {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.Toastify__toast--error .Toastify__toast-icon svg {
  color: var(--color-danger);
}

.Toastify__toast--info .Toastify__toast-icon svg {
  color: var(--color-info);
}

.Toastify__toast--success .Toastify__toast-icon svg {
  color: var(--color-success);
}

.Toastify__toast--warning .Toastify__toast-icon svg {
  color: var(--color-warning);
}
